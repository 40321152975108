import logo from "../../Resorces/Polt.svg";
import linkedin from "../../Resorces/LogotipoLinkedin.png";
import Instagram from "../../Resorces/LogotipoInstagram.png";
import twitter from "../../Resorces/LogotipoTwitter.png";
import {Link} from 'react-router-dom';
function handleLinkedin() {
  window.open('https://www.linkedin.com/company/poltapp', '_blank');
}
function handleX() {
  window.open('https://x.com/POLT2022', '_blank');
}
function handleInstagram() {
  window.open('https://www.instagram.com/poltapp?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==', '_blank');
}

function Header() {
  const handleClickScroll = (event) => {
    const element = document.getElementById(event);
    if (element && event === "Features") {
      // 👇 Will scroll smoothly to the top of the next section

      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest"  });
    }else if(element && (event === "Mission")){
      element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest"  });

    }else if(element && event === "Contacts"){
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest"  });
    };
  };
  return (
    <div className="Container Container-header">
      <div className="row">
        <div className="App-logo col-2 col-sm-2 col-md-1 col-lg-2 col-xl-2">
          <div onClick={() => handleClickScroll('Logo')}>
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="header-option col-8 col-sm-6 col-md-8 col-lg-8 col-xl-6">
            <h4 onClick={() => handleClickScroll('Features')} ><Link to="/">How it works</Link></h4>
            <h4 onClick={() => handleClickScroll('Mission')} ><Link to="/">Mission</Link></h4>
            <h4 ><Link to="/team">Team</Link></h4>
            <h4 onClick={() => handleClickScroll('Contacts')} ><Link to="/">Contacts</Link></h4>
        </div>
        <div className="Container-links col-2 col-sm-4 col-md-3 col-lg-2 col-xl-4">
          <div className="link-logo"  onClick={() => handleLinkedin()}>
            <img src={linkedin} alt="Linkedin" />
          </div>
          <div className="link-logo"  onClick={() => handleInstagram()}>
            <img src={Instagram} alt="Instagram" />
          </div>
          <div className="link-logo"  onClick={() => handleX()}>
            <img src={twitter} alt="X" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
