import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


import Header from './Components/Header/Header.js';
import HeaderMobile from './Components/Header/HeaderMobile.js';

import Footer from './Components/Footer/Footer.js';
import Team from './Components/Body/Team/Team.js';
import Features from './Components/Body/Features/Features.js';
import Mission from './Components/Body/Mission/Mission.js';



import reportWebVitals from './reportWebVitals';




const router = createBrowserRouter([
  {
    path: "/",
    element:
    <React.StrictMode>
      <Header />
      <HeaderMobile />
      <Features/>
      <Mission />
      <Footer />
    </React.StrictMode>
  },
  {
    path: "/team",
    element: 
    <React.StrictMode>
    <Header />
    <HeaderMobile />
    <Team/>
  </React.StrictMode>,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
