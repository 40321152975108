import Telemovel1 from "../../../Resorces/Telemovel1.png";
import Telemovel2 from "../../../Resorces/Telemovel2.png";
import Telemovel3 from "../../../Resorces/Telemovel3.png";
import Telemovel4 from "../../../Resorces/Telemovel4.png";
import Telemovel5 from "../../../Resorces/Telemovel5.png";
import Telemovel6 from "../../../Resorces/Telemovel6.png";
import Telemovel7 from "../../../Resorces/Telemovel7.png";


function Features() {
  return (
    <section id="Features">
      <div id="Features" className="Container Container-Features">
        <div className="row">
            <div className="Container-Quotes col-12">
              <p><span className="bold">POLT</span> is a polling app that improves the value of <span className="bold">collective knowledge.</span></p>
              <p>Pioneering <span className="bold">informed decision-making</span> for a more <span className="bold">enlightened</span>, proactive <span className="bold">global community.</span></p>
              <p><span className="bold">Join us</span> in shaping a <span className="bold">future</span> driven <span className="bold">by informed opinions</span> and <span className="bold">technology!</span></p>
            </div>
            <div className="Container-Header-Topic col-12">
              <h4>How it works</h4>
            </div>
            <div className="Container Container-Topic-wrapper col-12">
            <div className="Container-Topic-row row">
              <div className="Container-Topic col-sm-4 Col-md-4 Col-lg-3">
                <h6>Topic Selection</h6>
                <p>Select your topics of interest. We cover the current hottest and most relevant topics, organized according to your preference!</p>
                <span className="topic-img">
                 <img  src={Telemovel1} alt="Telemovel" />              
                </span>
              </div>
              <div className="Container-Topic col-sm-4 Col-md-4 Col-lg-3">
                <h6>Contextual Information</h6>
                <p>Don't waste time scrolling for information, we do the research for You. Be comfortable and armed with the Truth – it’s easy!</p>
                <span className="topic-img">
                 <img  src={Telemovel2} alt="Telemovel" />              
                </span>
              </div>
              <div className="Container-Topic col-sm-4 Col-md-4 Col-lg-3">
                <h6>Interactive Polls</h6>
                <p>Having the facts, the perspective and the nuances – Let the polls begin! Participate, decide and share your informed opinion!</p>
                <span className="topic-img">
                 <img  src={Telemovel3} alt="Telemovel" />              
                </span>
              </div>
              <div className="Container-Topic col-sm-4 Col-md-4 Col-lg-3">
                <h6>Reward System</h6>
                <p>With every poll completed you earn points. You can collect your rewards on your profile page - including money!</p>
                <span className="topic-img">
                 <img  src={Telemovel4} alt="Telemovel" />              
                </span>
              </div>
              <div className="Container-Topic col-sm-4 Col-md-4 Col-lg-3">
                <h6>Real Time Data</h6>
                <p>Best of all - get access to accurate results and know what other people think, on the topics you care about!</p>
                <span className="topic-img">
                 <img  src={Telemovel5} alt="Telemovel" />              
                </span>
              </div>
              <div className="Container-Topic col-sm-4 Col-md-4 Col-lg-3">
                <h6>Data Filters</h6>
                <p>With premium you can filter data by age, gender, location, etc. and visualize it in a fun and interactive way!</p>
                <span className="topic-img">
                 <img  src={Telemovel6} alt="Telemovel" />              
                </span>
              </div>
              <div className="Container-Topic col-sm-4 Col-md-4 Col-lg-3">
                <h6>Sponsored Polls</h6>
                <p>A special poll category, were companies are allowed to promote their market studies in compliance with POLT values.</p>
                <span className="topic-img">
                 <img  src={Telemovel7} alt="Telemovel" />              
                </span>
              </div>
            </div>
            </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
