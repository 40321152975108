import Antonio from "../../../Resorces/Antonio.png";
import Centeno from "../../../Resorces/Centeno.png";
import Pedro from "../../../Resorces/Pedro.png";



function Features() {
  const handleLinkedIn = (event) => {
    console.log(event);
    let personToLinkedIn = event;
    if( personToLinkedIn == 'Centeno'){
      window.open('https://www.linkedin.com/in/luis-centeno', '_blank');
    }else if(personToLinkedIn == 'Antonio'){
      window.open('https://www.linkedin.com/company/poltapp', '_blank');
    }else if(personToLinkedIn == 'Pedro'){
      window.open('https://www.linkedin.com/in/pedro-cardoso-652240233', '_blank');
    }
  }
  return (
    <section id="Team">
      <div id="Features" className="Container Container-Features">
        <div class="Container-Team col-12">
          <h4>Our <span className="bold">Team</span></h4>
        </div>
        <div className="row">
          <div className="teamIcon col-4">
            <img  src={Centeno} alt="Centeno" /> 
            <p>Luís Centeno (CEO)</p>
            <div onClick={() => handleLinkedIn('Centeno')}><span className="bold">LinkedIn</span></div>
          </div>
          <div className="teamIcon col-4">
            <img  src={Antonio} alt="Antonio" /> 
            <p>António Fernandes (COO)</p>
            <div onClick={() => handleLinkedIn('Antonio')}><span className="bold">LinkedIn</span></div>
          </div>
          <div className="teamIcon col-4">
            <img  src={Pedro} alt="Pedro" /> 
            <p>Pedro Cardoso (CFO)</p>
            <div onClick={() => handleLinkedIn('Pedro')}><span className="bold">LinkedIn</span></div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
