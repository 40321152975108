import logo from "../../Resorces/Polt.svg";
import menuBottom from "../../Resorces/MobileMenuOpen.png";
import linkedin from "../../Resorces/LogotipoLinkedin.png";
import Instagram from "../../Resorces/LogotipoInstagram.png";
import twitter from "../../Resorces/LogotipoTwitter.png";
import menuBottomClose from "../../Resorces/MobileMenuClose.png";
import {Link} from 'react-router-dom';

function handleLinkedin() {
    window.location.href ="https://www.linkedin.com/company/poltapp";
}

function handleX() {
  window.open('https://x.com/POLT2022', '_blank');
}
function handleInstagram() {
  window.open('https://www.instagram.com/poltapp?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==', '_blank');
}



function handleOpenMenu() {
    let menu = document.getElementById("menuBox");
    menu.classList.add("display");
}

function handlecloseMenu() {
    let menu = document.getElementById("menuBox");
    menu.classList.remove("display");
}

function HeaderMobile() {
  const handleClickScroll = (event) => {
    const element = document.getElementById(event);
    let menu = document.getElementById("menuBox");
    if (element && event === "Features") {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest"  });
      menu.classList.remove("display");
    }else if(element && (event === "Mission")){
      element.scrollIntoView({ behavior: "smooth", block: "center"  });
      menu.classList.remove("display");
    }else if(element && event === "Contacts"){
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest"  });
      menu.classList.remove("display");
    }else{
      menu.classList.remove("display");
    };
  };
  return (
    <div className="Container Container-header-mobile">
      <div className="row">
        <div className="App-logo col-6 col-sm-6 col-md-1 col-lg-2 col-xl-2">
          <div onClick={() => handleClickScroll('Logo')}>
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="Container-links col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
          <div className="menuBottom">
            <img src={menuBottom} alt="menuBottom" onClick={() => handleOpenMenu()}/>
          </div>
          <div id="menuBox" className="menuContainer">
                <div className="menu-wrapper">
                    <div className="App-logo col-6 col-sm-6 col-md-1 col-lg-2 col-xl-2">
                        <div onClick={() => handleClickScroll('Logo')}>
                            <img src={logo} alt="logo" />
                        </div>
                    </div>
                    <div className="Container-links  col-6 col-sm-6 col-md-1 col-lg-2 col-xl-2">

                        <div className="menuBottom" onClick={() => handlecloseMenu()}>
                            <img src={menuBottomClose} alt="logo" />
                        </div>
                    </div>
                </div>
                <div className="menu-option col-12">
                  <h4 onClick={() => handleClickScroll('Features')} ><Link to="/">How it works</Link></h4>
                  <h4 onClick={() => handleClickScroll('Mission')} ><Link to="/">Mission</Link></h4>
                  <h4 onClick={() => handleClickScroll()}><Link to="team">Team</Link></h4>
                  <h4 onClick={() => handleClickScroll('Contacts')} ><Link to="/">Contacts</Link></h4>
                </div>
                <div className="Container-links-mobile">
                  <div className="menu-option-link col-4" onClick={() => handleLinkedin()}>
                          <img src={linkedin} alt="Linkedin" />
                  </div>
                  <div className="menu-option-link col-4" onClick={() => handleInstagram()}>
                          <img src={Instagram} alt="Instagram" />
                  </div>
                  <div className="menu-option-link col-4" onClick={() => handleX()}>
                          <img src={twitter} alt="X" />
                  </div>
                </div>
                
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderMobile;
