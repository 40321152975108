import androide from '../../Resorces/google-play-badge.svg';
import apple from '../../Resorces/app-store-badge.svg';

function handleGoogleStore() {
  window.open('https://play.google.com/store/apps/details?id=com.bitnata.polt', '_blank');
}

function handleAppleStore() {
  window.open('https://apps.apple.com/pt/app/polt/id6479333110?l=en-GB', '_blank');
}

function Footer() {
  return (
    <div id="Contacts" className="Container-footer">
      <div className="Containers">
        <div className="row">
          <div className="Container-Quotes col-12"> 
            <p><span className="bold">Download now</span>, participate in our polls and change the Future with <span className="bold">POLT</span>.</p>
          </div>
          <div className='app-container'>
            <img src={androide} className="App-store" alt="Androide Store" onClick={() => handleGoogleStore()}/>
            <img src={apple} className="App-store" alt="Apple Store" onClick={() => handleAppleStore()}/>
          </div>
          <div className="Footer-Quotes">
            <span><strong>Mail:</strong> contacts@poltapp.com</span>
          </div>
          <div className="Footer-Quotes">
            <span>Polt 2023</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
