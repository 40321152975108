import Figura from "../../../Resorces/FiguraDesktop.png";
import Icon1 from "../../../Resorces/MissionIcon1.png";
import Icon2 from "../../../Resorces/MissionIcon2.png";
import Icon3 from "../../../Resorces/MissionIcon3.png";




function HowItWorks() {
  return (
    <div id="Mission" className="Container-Mission">
      <div className="Container Container-Mission-wrapper">
        <div className="items-wrapper row">
          <div className="Mission-header col-12">
            <h4>Our mission</h4>
          </div>
          <div className="Container-Mission-Itens-wrapper container">
            <div className="Mission-item-wraper row">
              <div className="Mission-item-wraper-container col-sm-4 Col-md-4 Col-lg-3">
                <div className="Mission-item ">
                  <img  src={Icon1} alt="Figura" /> 
                </div>
                <h3>Fighting Misinformation</h3>
                <span>POLT cuts through information overload to spotlight crucial issues through AI and professional insight, fostering informed discussions for a clearer societal narrative.</span>
              </div>
              <div className="Mission-item-wraper-container col-sm-4 Col-md-4 Col-lg-3">
                <div className="Mission-item ">
                  <img  src={Icon2} alt="Figura" /> 
                </div>
                <h3>Public Opinion Accessibility</h3>
                <span>We're committed to fostering a society that's connected, and empowered with truth and consensus, transforming how public opinion is formed and shared in our digital age.</span>
              </div>
              <div className="Mission-item-wraper-container col-sm-4 Col-md-4 Col-lg-3">
                <div className="Mission-item ">
                  <img  src={Icon3} alt="Figura" /> 
                </div>
                <h3>Improving Polling Methods</h3>
                <span>POLT is revolutionizing traditional polling with engaging, accurate methods, driving a movement for anonymous democratic participation through shared knowledge.</span>
                <span>Your opinions, your insights, your decisions – they all have a place here.</span>
              </div>
            </div>
          </div>
          <div className="Mission-footer col-12">
            <h5>“Power derives from knowledge”</h5>
            <span>Michio Kaku</span>
          </div>
        </div>
      </div>
      <span className="rigth">
        <img  src={Figura} alt="Figura" />   
      </span>
    </div>
  );
}

export default HowItWorks;
